<template>
  <div>
    <div class="searchBox" :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker v-model="dataForm.takeGoodsTime" type="daterange" align="right" unlink-panels
            range-separator="-" size="small" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商名称">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客服状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.careStatus" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option label="已审核" value="2"></el-option>
            <el-option label="未审核" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主管状态" prop="deptStatus">
          <el-select class="item-choose" v-model="dataForm.deptStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已审核" value="1"></el-option>
            <el-option label="未审核" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="财务状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已审核" value="2"></el-option>
            <el-option label="未审核" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.orderStatus" size="small" @change="getDataList()" filterable
            clearable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in dictTypeMap.orderStatusMap" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="共赢车辆" prop="allWinVehicle">
          <el-select class="item-choose" v-model="dataForm.allWinVehicle" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('order:cost:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right; display: flex; justify-content: flex-end; align-items: center;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('care')" type="success" v-if="newAuth('order:cost:auditCare')" :disabled="selectFeeData.length ? false : true">客服核对</el-button>
            <el-button size="small" @click="operatopnList('dept')" type="info" v-if="newAuth('request:deptStatus:audit')" :disabled="selectFeeData.length ? false : true">主管核对</el-button>
            <el-button size="small" @click="operatopnList('pass')" type="primary" v-if="newAuth('order:cost:audit')" :disabled="selectFeeData.length ? false : true">财务审核通过</el-button>
            <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox" :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-table :data="dataList"
                border
                size="small"
                ref="tableRef"
                :height="tableHeight"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                @selection-change="handleSelectionChange"
                v-loading="dataListLoading"
                style="width: 100%;"
                :cell-class-name="getCellClassName">
        <el-table-column type="selection" width="35" align="left" />
        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
            :class="item.styleClass"
            :class-name="item.styleClass">
          <template slot-scope="scope">
            <!-- 费用小计 -->
            <template v-if="item.prop == 'costInfo.totalMoney'">
              {{ isFreightFeeShow ? getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) : '' }}
            </template>
            <!-- 运费 -->
            <template v-else-if="item.prop == 'costInfo.freightFee'">
              {{ isFreightFeeShow ? getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) : '' }}
            </template>
            <!-- 费用图片 -->
            <template v-else-if="item.prop == 'costImage'">
              <span @click="viewPhoto(scope.row, costImageList)" style="color: blue;cursor:pointer">查看</span>
            </template>
            <!-- 状态 -->
            <template v-else-if="item.prop == 'status'">
              <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
              <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
              <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
              <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
              <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
              <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
            </template>
            <!-- 客服状态 -->
            <template v-else-if="item.prop == '客服状态'">
              <el-tag v-if="scope.row.orderInfo.careStatus == 2" size="small" type="success">已审核</el-tag>
              <el-tag v-else size="small" type="danger">未审核</el-tag>
            </template>
            <!-- 主管状态 -->
            <template v-else-if="item.prop == '主管状态'">
              <el-tag v-if="scope.row.orderInfo.deptStatus == 1" size="small" type="success">已审核</el-tag>
              <el-tag v-else size="small" type="danger">未审核</el-tag>
            </template>
            <!-- 财务状态 -->
            <template v-else-if="item.prop == '财务状态'">
              <el-tag v-if="scope.row.costInfo.status == 2" size="small" type="success">已审核</el-tag>
              <el-tag v-else size="small" type="danger">未审核</el-tag>
            </template>
            <!-- 确认状态 -->
            <template v-else-if="item.prop == '确认状态'">
              <el-tag v-if="scope.row.payCostInfo != null && scope.row.payCostInfo.confirmStatus == 2" size="small" type="danger">司机待确认</el-tag>
              <el-tag v-else-if="scope.row.payCostInfo != null && scope.row.payCostInfo.confirmStatus == 3" size="small" type="success">司机已确认</el-tag>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="left" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('order:cost:find')" type="text" size="small"
                         @click="operatopnList('cost', 'view', scope.row)">详情</el-button>
              <el-button v-if="newAuth('order:cost:audit') && scope.row.costInfo.status !== 2" type="text" size="small"
                         @click="operatopnList('pass', 'edit', scope.row)">费用审核</el-button>
              <el-button v-if="newAuth('request:deptStatus:audit') && scope.row.orderInfo.deptStatus !== 1" type="text" size="small"
                         @click="operatopnList('dept', 'edit', scope.row)">主管审核</el-button>
              <el-button v-if="newAuth('order:cost:update')" type="text" size="small"
                         @click="operatopnList('cost', 'edit', scope.row)">费用修改</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                     background :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <!-- 核销弹框 -->
    <receipt-off-popup ref="receiptOff" @refreshDataList="getDataList"></receipt-off-popup>
    <view-image ref="viewPhoto"></view-image>

    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from '@/components/table'
import costModification from './detail/costModification-popup'
import receiptOffPopup from './detail/receipt-off-popup'
import viewImage from '@/views/commonPop/viewImage'
import qs from 'qs'
import * as myJsonClon from "@/utils/myJsonClone"
export default {
  data () {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 1,
        configDefaultColumn: [
          {label: '提货时间',prop: 'deliveryTimeNode',fixed: 'left',width: '105',check: true},
          {label: '客户名称',prop: 'customerNameNode',fixed: 'left',width: '70',check: true},
          {label: '司机车牌',prop: 'vehiclePlateNode',fixed: 'left',width: '70',check: true},
          {label: '进仓单号',prop: 'inWarehouseNoNode',width: '80',check: true,fixed: undefined},
          {label: '订单类型',prop: 'orderTypeNode',width: '60',formatter: 'orderType',check: true,fixed: undefined},
          {label: '订单车型',prop: 'vehicleTypeNode',width: '60',formatter: 'dictConvertVehicle',check: true,fixed: undefined},
          {label: '报关方式',prop: 'entranceTypeNode',width: '80',formatter: 'entranceType',check: true,fixed: undefined},
          {label: '提货方',prop: 'factoryName',width: '100',check: true,fixed: undefined},
          {label: '提货地址',prop: 'fullAddress',width: '180',check: true,fixed: undefined},
          {label: '收货方',prop: 'factoryNameNode',width: '100',check: true,fixed: undefined},
          {label: '收货地址',prop: 'fullAddressNode',width: '180',check: true,fixed: undefined},
          {label: '公里数(KM)',prop: 'orderInfo.distance',width: '180', formatter: 'getDistanceValue',check: true,fixed: undefined},
          {label: '费用小计',prop: 'costInfo.totalMoney',width: '80',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '运费',prop: 'costInfo.freightFee',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '报关/入仓费',prop: 'costInfo.entranceFee',width: '75',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '高速费',prop: 'costInfo.highFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '过磅费',prop: 'costInfo.weighFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '装卸费',prop: 'costInfo.loadFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '押夜/超时费',prop: 'costInfo.overtimeFee',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '提货费',prop: 'costInfo.takeFee',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '加点费',prop: 'costInfo.addFee',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '停车费',prop: 'costInfo.parkingFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '其他费用',prop: 'costInfo.otherFee',width: '55',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '其他费用备注',prop: 'costInfo.otherFeeRemark',check: true,fixed: undefined,styleClass: 'my-table-cell-v3'},
          {label: '异常说明',prop: 'groupExplain',width: '60',check: true,fixed: undefined},
          {label: '费用小计',prop: 'payCostInfo.auditTotalMoney',width: '80',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '运费',prop: 'payCostInfo.freightFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '报关/入仓费',prop: 'payCostInfo.entranceFee',width: '75',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '高速费',prop: 'payCostInfo.highFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '过磅费',prop: 'payCostInfo.weighFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '装卸费',prop: 'payCostInfo.loadFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '押夜/超时费',prop: 'payCostInfo.overtimeFee',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '提货费',prop: 'payCostInfo.takeFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '加点费',prop: 'payCostInfo.addFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '停车费',prop: 'payCostInfo.parkingFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '打印费用',prop: 'payCostInfo.printFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '维修费',prop: 'payCostInfo.maintainFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '现金加油费',prop: 'payCostInfo.refuelFee',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '现金过路费',prop: 'payCostInfo.passingFee',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '油卡费',prop: 'payCostInfo.refuelCardFee',width: '65',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '粤通卡费',prop: 'payCostInfo.passingCardFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '其他费用',prop: 'payCostInfo.otherFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '其他费用备注',prop: 'payCostInfo.otherFeeRemark',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '司机借支',prop: 'payCostInfo.driverBorrowing',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '扣款',prop: 'payCostInfo.deductionsFee',width: '40',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell'},
          {label: '装卸费',prop: 'driverRoyalty.loadFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '住宿费',prop: 'driverRoyalty.stayFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '加班费',prop: 'driverRoyalty.extraFee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '司机提成费',prop: 'driverRoyalty.royaltyFee',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '基本工资',prop: 'driverRoyalty.baseFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '绩效工资',prop: 'driverRoyalty.meritsFee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '使用APP奖励',prop: 'driverRoyalty.appFee',width: '80',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '租房费',prop: 'driverRoyalty.other1Fee',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '油耗奖励',prop: 'driverRoyalty.other2Fee',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '司机费用小计',prop: 'driverRoyalty.payableAmount',width: '80',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '请假扣款',prop: 'driverRoyalty.accumulationFund',width: '50',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '扣减安全奖',prop: 'driverRoyalty.safetyAward',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '扣减违章/油耗',prop: 'driverRoyalty.oilConsumption',width: '90',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '分摊水电费',prop: 'driverRoyalty.hydropower',width: '70',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '个税',prop: 'driverRoyalty.personalIncomeTax',width: '40',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '司机借支',prop: 'driverRoyalty.driverBorrowing',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '扣社保/公积金',prop: 'driverRoyalty.deductionsFee',width: '85',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '其他费用',prop: 'driverRoyalty.otherExpenses',width: '60',formatter: 'getNumValue',check: true,fixed: undefined,styleClass: 'my-table-cell-v2'},
          {label: '费用图片',prop: 'costImage',width: '60',check: true,fixed: undefined},
          {label: '件数',prop: 'orderGoods.pieceNum',width: '40',check: true,fixed: undefined},
          {label: '体积',prop: 'orderGoods.volume',width: '40',check: true,fixed: undefined},
          {label: '重量',prop: 'orderGoods.weight',width: '40',check: true,fixed: undefined},
          {label: '指派车型',prop: 'vehiclePlan.vehicleTypeName',width: '80',check: true,fixed: undefined},
          {label: '司机姓名',prop: 'vehiclePlan.driverName',width: '60',check: true,fixed: undefined},
          {label: '司机电话',prop: 'vehiclePlan.driverPhone',width: '90',check: true,fixed: undefined},
          {label: '客户单号',prop: 'orderInfo.customerSelfNo',width: '100',check: true,fixed: undefined},
          {label: '客户联系人',prop: 'orderInfo.customerContactName',width: '60',check: true,fixed: undefined},
          {label: '客户联系电话',prop: 'orderInfo.customerContactPhone',width: '90',check: true,fixed: undefined},
          {label: '下单时间',prop: 'orderInfo.commitTime',width: '120',check: true,fixed: undefined},
          {label: '下单人',prop: 'orderInfo.commitName',width: '70',check: true,fixed: undefined},
          {label: '订单号',prop: 'orderInfo.orderNo',width: '110',check: true,fixed: undefined},
          {label: '车次号',prop: 'vehiclePlan.planNo',width: '110',check: true,fixed: undefined},
          {label: '订单状态',prop: 'status',width: '60',check: true,fixed: undefined},
          {label: '确认状态',prop: '确认状态',width: '70',check: true,fixed: undefined},
          {label: '客服状态',width: '60',prop: '客服状态',fixed: 'right',check: true},
          {label: '主管状态',width: '60',prop: '主管状态',fixed: 'right',check: true},
          {label: '财务状态',width: '60',prop: '财务状态',fixed: 'right',check: true},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
        configDefaultPropColumnMap: new Map(),
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        takeGoodsTime: null,
        planNo: null,
        status: null,
        orderType: null,
        vehicleType: null,
        vehiclePlate: null,
        inWarehouseNo: null,
        customerContactName: null,
        customerName: null,
        motorcadeName: null,
        factoryName: null,
        driverName: null,
        allWinVehicle: null,
        customerIdList: [],
        orderStatus: null,
        careStatus: null,
        receiptStatus: null
      },
      tableHeight: '65vh',
      costType: 1,
      listType: 0,
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      costImageList: [7050, 7051, 7052, 7053, 7054, 7055, 7056, 7057, 7001, 7002, 7003, 7004, 7005, 7006, 7007, 7008],
      orderException: [6020],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: [],
      receiptOffVisible: false,
      isFreightFeeShow: false, // 默认不可见
      spanArr: []
    }
  },
  components: {
    costModification,
    viewImage,
    receiptOffPopup,
    configTable
  },
  created () {
    this.initConfigTable()

    // 判断是否查询所属客户
    if (this.newAuth('order:cost:customer')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    if (this.newAuth('order:cost:freightFee')) {
      this.isFreightFeeShow = true
    }
    this.setDefaultDate()
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {
          this.config.configDefaultPropColumn.push({prop: x.prop})
          this.config.configDefaultPropColumnMap.set(x.prop,x)
        })
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    getCellClassName({ row, column, rowIndex, columnIndex }) {
      let newVar = this.config.configDefaultPropColumnMap.get(column.property);
      if (newVar && newVar.styleClass) {
        return newVar.styleClass;
      }
      return '';
    },
    // -----------------动态表格模式end-----------------

    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100) !important'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      if ((this.getIndex) === rowIndex) {
        return 'selected-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 查看照片
    viewPhoto (row, types) {
      this.$refs.viewPhoto.init(row.costInfo.id, types)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (column.property && column.property.indexOf('otherFeeRemark') > -1) {
            return ''
          }
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && (column.property.indexOf('costInfo.') > -1 || column.property.indexOf('payCostInfo.') > -1 || column.property.indexOf('driverRoyalty.') > -1)) {
            if (column.property.indexOf('driverRoyalty.') > -1 && !item.driverRoyalty) {
              return 0
            }
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getDistanceValue (row, cell, value) {
      return parseFloat((value * 0.001).toFixed(1))
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (row) {
      let costInfo = row.costInfo || {}
      let payCostInfo = row.payCostInfo || {}
      let driverRoyalty = row.driverRoyalty || {}
      for (let c in costInfo) {
        costInfo[c] = this.getValue(costInfo[c])
      }
      for (let c in payCostInfo) {
        payCostInfo[c] = this.getValue(payCostInfo[c])
      }
      for (let c in driverRoyalty) {
        driverRoyalty[c] = this.getValue(driverRoyalty[c])
      }

      // 合计参数，按顺序返回
      let param = []
      param.push('合计')
      this.config.configDefaultPropColumn.forEach(x => {
        let value = _.get({ costInfo, payCostInfo, driverRoyalty }, x.prop);
          param.push(value == undefined ? '' : value)
      })
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    receiptUpdate (receiptStatus, row) {
      if (receiptStatus === 1) { // 回单撤销
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【回单撤销】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/order/receiptUpdate'),
            method: 'post',
            data: {
              ids: ids,
              receiptStatus: receiptStatus
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (receiptStatus === 2) { // 回单寄出
        this.receiptOffVisible = true
        let ids = []
        this.selectFeeData.forEach(item => {
          if (item.orderInfo.receiptStatus !== receiptStatus) {
            ids.push(item.orderInfo.id)
          }
        })
        if (ids.length === 0) {
          this.$message.error('没有符合寄出的数据，请检查是否勾选正确')
          return
        }
        this.$refs.receiptOff.init(receiptStatus, ids)
      }
    },
    operatopnList (type, todo, row) {
      if (type === 'pass') {
        // this.$refs.pass.init()
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【费用审核通过】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/costInfo/orderCost/audit'),
            method: 'post',
            data: {
              ids: ids,
              status: 2
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (type === 'cost') {
        this.$refs.cost.init(type, todo, row)
      } else if (type === 'care') {
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【客服审核通过】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/order/careStatus/audit'),
            method: 'post',
            data: {
              ids: ids,
              status: 2
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (type === 'dept') {
        let ids = []
        let check = true
        if (row) {
          if (row.orderInfo.careStatus != 2) {
            this.$message.error('无法审核,需客服审核后才可操作!')
            check = false
          }
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            if (item.orderInfo.careStatus != 2) {
              this.$message.error('无法审核,需客服审核后才可操作!')
              check = false
            }
            ids.push(item.orderInfo.id)
          })
        }
        if (check) {
          this.$confirm('正在执行【主管审核通过】操作，是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$http({
              url: this.$http.adornUrl('/order/deptStatus/audit'),
              method: 'post',
              data: {
                ids: ids,
                status: 2
              }
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.getDataList()
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }).catch(() => { })
        }
      }
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/costExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'costType': this.costType,
          'orderType': this.dataForm.orderType,
          'status': this.dataForm.status,
          'factoryName': this.dataForm.factoryName,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerContactName': this.dataForm.customerContactName,
          'customerName': this.dataForm.customerName,
          'vehiclePlan.planNo': this.dataForm.planNo,
          'vehiclePlan.vehicleTypeId': this.dataForm.vehicleType,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'vehiclePlan.motorcadeName': this.dataForm.motorcadeName,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '订单费用核对表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'costType': this.costType,
        'orderType': this.dataForm.orderType,
        'status': this.dataForm.status,
        'factoryName': this.dataForm.factoryName,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'customerContactName': this.dataForm.customerContactName,
        'customerName': this.dataForm.customerName,
        'vehiclePlan.planNo': this.dataForm.planNo,
        'vehiclePlan.vehicleTypeId': this.dataForm.vehicleType,
        'vehiclePlan.driverName': this.dataForm.driverName,
        'vehiclePlan.motorcadeName': this.dataForm.motorcadeName,
        'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
        'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle,
        'customerIdList': this.dataForm.customerIdList,
        'orderStatus': this.dataForm.orderStatus,
        'careStatus': this.dataForm.careStatus,
        'receiptStatus': this.dataForm.receiptStatus,
        'deptStatus': this.dataForm.deptStatus,
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/costInfo/audit/findList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
          await this.getAllSummaries(data.auditCost || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
       this.tableHeight = '67vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
  }
}
</script>
<style lang="less" scoped>
div#pane-costManagement-chargeablePool {
}
:deep(.my-table-cell) {
  background-color: antiquewhite!important;
}
:deep(.my-table-cell-v2) {
  background-color: beige!important;
}
:deep(.my-table-cell-v3) {
  background-color: aliceblue!important;
}
</style>
